exports.WEBFORM = {
  ESTAMOS_LISTOS_PARA_AYUDARTE: '_estamos_listos_para_ayudarte_',
  CAREERS: 'careers',
  DESCARGA_NUESTRO_WHITEPAPER: 'descarga_nuestro_whitepaper',
  E_BOOK_FORM_V3: 'e_book_form_v3',
  DOWNLOAD_OUR_WHITEPAPER: 'download_our_whitepaper',
  GET_OUR_WHITEPAPER: 'get_our_whitepaper',
  LETS_GET_TO_KNOW_YOU: 'let_s_get_to_know_you',
  CONTACT: 'contact',
  OBTENGA_NUESTRO_WHITEPAPER: 'obtenga_nuestro_whitepaper',
  PROJECT_MANAGER_SKILLS: 'project_manager_skills',
  REGISTER_EVENT: 'register_event',
  SALE_TEST_QUESTIONS: 'sale_test_questions',
  SALES_TEAM_TEST: 'sales_team_test',
  TRABAJO: 'trabajo',
  WE_ARE_READY_TO_HELP_YOU: 'we_are_ready_to_help_you_',
  DESCARGA_NUESTRO_EBOOK: '_descarga_nuestro_ebook_',
  REGISTRATE: '_registrate_',
  TRABAJEMOS_JUNTOS: '_trabajemos_juntos_'
};
