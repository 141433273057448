import React from 'react';
import { useTranslation } from 'react-i18next';

type getContentBlurProps = {
  content: React.ReactElement;
};

export default function ContentBlur({ content }: getContentBlurProps): React.ReactElement {
  const { t } = useTranslation();

  return (
    <div className="position-relative">
      <div className="position-absolute w-100 text-center h4 mt-5 p-5">{`${t('Content not available')}`}</div>
      <div className="blur">
        {content}
      </div>
    </div>
  );
}
